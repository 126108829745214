@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-spin .ant-spin-nested-loading,
.custom-spin .ant-spin-container {
    background: transparent !important;
}

.box_wrap p span {
  font-weight: normal;
}

.box_wrap p span span {
  color: green;
  font-weight: bold;
  margin-right: 5px;
}

/* General styles for the iframe container */
.iframe-container {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  width: 100%;
  max-width: 1000px; /* Max width for desktop */
  margin: 0 auto;
  overflow: hidden;
  border-radius: 8px; /* Optional: Adds rounded corners */
}

/* Styles for the iframe itself */
.dextools-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .iframe-container {
    padding-top: 100%; /* Adjust aspect ratio for mobile */
  }
}

/* Desktop-specific styles */
@media (min-width: 768px) {
  .iframe-container {
    padding-top: 56.25%; /* Standard 16:9 aspect ratio for desktop */
  }
}

h1 {
  color: #fff; /* Set text color to white */
  text-align: center; /* Optional: Align the text to the center */
  margin-bottom: 20px; /* Optional: Add some space below the heading */
}

.smart-contract-box {
  display: flex;
  justify-content: center;
  align-items: center; /* Vertically center the content */
  padding: 10px;
  border-radius: 8px;
  background: transparent; /* Default background is transparent */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Box shadow remains constant */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.smart-contract-box:hover {
  background: linear-gradient(135deg, #BE60B0, #EAAC25); /* Gradient on hover */
}


.smart-contract-text {
  font-size: 30px; /* Desktop font size */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  line-height: 1;
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
  background: transparent;
  color: transparent;
  transition: all 0.3s ease-in-out;
  padding: 10px;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .smart-contract-text {
    font-size: 10px; /* Mobile font size */
  }
}


.copy-button {
  font-size: 18px;
  margin-left: 10px;
  outline: none !important; /* Ensure no outline for button */
  box-shadow: none !important; /* Remove default Ant Design shadow */
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: #EAAC25;  /* Change the color when hovering */
  text-decoration: underline; /* Optional underline effect */
}

.copy-button.disabled {
  pointer-events: none; /* Prevent any interaction */
  outline: none !important; /* Ensure no focus ring */
  box-shadow: none !important;
}

/* Remove outline and box-shadow for the Button */
.copy-button:focus,
.copy-button:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

/* Common button styles */
.reveal-button {
  background: transparent !important;
  color: #fff !important;
  border: 2px solid #fff !important;
  padding: 20px 30px; /* Updated padding - 15px for top/bottom and 30px for left/right */
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effect */
}


/* Mobile font size */
@media (max-width: 768px) {
  .reveal-button {
    font-size: 20px; /* Mobile font size */
  }
}

/* Desktop font size */
@media (min-width: 768px) {
  .reveal-button {
    font-size: 30px; /* Desktop font size */
    font-weight: bold;
  }
}

/* Hover effect with gradient */
.reveal-button:hover {
  background: linear-gradient(135deg, #BE60B0, #EAAC25) !important;
  border: none !important;
  color: #fff; /* Ensure text remains white */
}

.header_wrap {
  background: rgba(0, 0, 0, 0.85); /* Semi-transparent dark background */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.8); /* Dark shadow for a menacing vibe */
  backdrop-filter: blur(5px); /* Slight blur for a sleek effect */
  border-bottom: 1px solid rgba(255, 0, 0, 0.7); /* Red accent border */
}

.navbar-nav .nav-link {
  color: #fff; /* Bright text for readability */
  font-family: 'MedievalSharp', sans-serif; /* Villainous font */
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.3s, text-shadow 0.3s; /* Smooth transition effects */
}

.navbar-nav .nav-link:hover {
  color: #ff0000; /* Red hover effect */
  text-shadow: 
      0px 0px 5px rgba(255, 0, 0, 0.9), 
      0px 0px 10px rgba(255, 0, 0, 0.7); /* Glowing effect on hover */
}

.navbar-toggler {
  background: #ff0000; /* Red button for the toggler */
  border: 1px solid rgba(255, 0, 0, 0.7);
  color: #000;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: 0px 0px 10px rgba(255, 0, 0, 0.8); /* Glow effect when toggler is active */
}

.nav_btn select {
  background: rgba(255, 0, 0, 0.9); /* Red dropdown background */
  color: #fff; /* White text */
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-family: 'MedievalSharp', sans-serif;
}

.nav_btn select:focus {
  outline: none;
  box-shadow: 0px 0px 10px rgba(255, 0, 0, 0.8); /* Glow effect when dropdown is active */
}

.logo_light {
  display: none; /* Hide the light logo */
}

.logo_dark {
  display: inline;
  max-width: 100%; /* Ensures the logo doesn't exceed its container */
    height: auto; /* Maintains aspect ratio */
    max-height: 60px; /* Show the dark logo for better contrast */
}

.social-icon {
  position: relative;
}

.social-link {
  display: block;
  position: relative;
}

.social-name {
  display: none; /* Hide the name by default */
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1;
}

.social-link:hover .social-name {
  display: block;
}

.social-link:hover i {
  transform: scale(1.5); 
}
