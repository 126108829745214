.custom-input, .custom-input:focus, .custom-input:hover {
    border-radius: 20px !important;
    border: 2px solid white !important;
    background-color: #0c0e27 !important;
    color: white !important;
    font-weight: bold !important;
}

.custom-input input::placeholder {
    color: white !important;
    font-weight: bold !important;
}

.custom-label {
    color: white !important;
    font-weight: bold;
}

.drop-label {
    color: white !important;
    font-size: medium;
    line-height: 1; /* Tightens line spacing */
    display: inline-block; /* Forces multiline support */
    margin: 0; /* Remove margin if any */
    padding: 0; /* Remove padding if any */
}

.custom-spin {
    display: block;
    margin-top: 10px; /* Space between the button and the spinner */
    text-align: center; /* Centers the spinner */
}

.custom-spin-tip {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px; /* Adds spacing below the spinner */
    text-align: center; /* Centers the text */
}

.buy-button-legend {
    margin-top: 0px;
    font-size: 14px;
    color: #fff;
    text-align: center;
}

/* Default style for desktop (no centering) */
.input-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-input {
    width: 300px;
    text-align: left; /* Default left-aligned text */
}

/* Mobile style (centering the input) */
@media (max-width: 767px) {
    .input-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .centered-input {
        width: 90%; /* Adjust width to fit better on small screens */
        text-align: center; /* Center text inside the input */
    }
}

/* Default style for desktop (keep original alignment) */
.buy-button-legend p.centered-text {
    text-align: center; /* Default alignment for desktop */
}

/* Mobile style (centering the text) */
@media (max-width: 767px) {
    .buy-button-legend p.centered-text {
        text-align: center; /* Center the text on mobile */
    }
}

/* Progress Bar Container */
.progress-container {
    background-color: #0c0e27; /* Dark blue background */
    padding: 10px;
    border-radius: 8px;
    margin: 10px 0;
    text-align: center;
}

/* Progress Bar Text */
.progress-info {
    color: #ffffff; /* White text */
    font-size: 1rem;
    margin-top: 10px;
}

/* Animated Progress Bar Style */
.ant-progress-bg {
    background-image: linear-gradient(45deg, #ff7e5f, #feb47b, #86a8e7, #ff9a9e); /* Fun gradient colors */
    background-size: 200% 200%;
    animation: gradientShift 3s ease infinite, bounceEnd 0.5s ease-in-out;
}

/* Keyframes for gradient shift */
@keyframes gradientShift {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

/* Bounce animation for a fun ending */
@keyframes bounceEnd {
    0% { transform: scaleX(1); }
    85% { transform: scaleX(1.1); }
    100% { transform: scaleX(1); }
}

/* Progress Track */
.ant-progress-outer {
    background-color: #1a2a3a; /* Darker blue for background of progress track */
    border-radius: 8px;
    padding: 8px;
}

/* Progress Percentage Text */
.ant-progress-text {
    color: #ffffff !important; /* White text for percentage */
    font-weight: bold;
    font-size: 1rem;
}
